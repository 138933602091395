import React from "react"
import styled from "styled-components"
import Image from "gatsby-plugin-sanity-image"
import RenderBlock from "../components/RenderBlock"

const StyledArtists = styled.div`
  margin: 60px 0 0 0;
  .section-title {
    text-transform: uppercase;
    text-align: center;
    color: var(--white);
    margin-bottom: 40px;
    font-weight: bold;
    letter-spacing: 12px;
    font-size: 26px;
    filter: drop-shadow(2px 4px 6px #000);
    @media (max-width: 600px) {
      font-size: 20px;
      letter-spacing: 8px;
    }
  }

  .cards {
    display: flex;
    flex-direction: column;
    .card {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      .left {
        width: 48%;
        display: flex;
        justify-content: center;
        @media (max-width: 1000px) {
          width: 100%;
        }

        .image {
          max-width: 300px;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          -webkit-user-drag: none;
          -khtml-user-drag: none;
          -moz-user-drag: none;
          -o-user-drag: none;
          @media (max-width: 600px) {
            max-width: 100%;
          }
        }
      }
      .right {
        width: 48%;
        @media (max-width: 1000px) {
          width: 100%;
        }
        .name {
          margin: 0 0 15px 0;
          color: var(--red);
          text-transform: uppercase;
          font-weight: 600;
          font-size: 1.2em;
        }
        .artist-block {
          p {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
`

const Artists = ({ artists }) => {
  const displayedArtists = artists
    .sort((a, b) => a?.order - b?.order)
    .map((artist, index) => {
      return (
        <div className={`card card--${artist.order}`} key={index}>
          {artist.image && (
            <div className="left">
              <Image
                className={"image"}
                width={500}
                {...artist.image}
                alt={`dark-spectrum-${artist.name}`}
              />
            </div>
          )}
          <div className="right">
            <h3 className="name">{artist.name}</h3>
            <RenderBlock block={artist.bio} className={"artist-block"} />
          </div>
        </div>
      )
    })

  return (
    <StyledArtists>
      <h2 className="section-title">Our Artists</h2>
      <div className="cards">{displayedArtists}</div>
    </StyledArtists>
  )
}

export default Artists
