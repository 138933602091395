import React from "react"
import { graphql, navigate } from "gatsby"
import styled from "styled-components"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Nav from "../components/Nav"

import MobileMenu from "../components/MobileMenu"

import Banner from "../components/Banner"
import PageTitle from "../components/ui/PageTitle"
import RenderBlock from "../components/RenderBlock"

import { firstCategory } from "../utils/helpers"
import Artists from "../components/Artists"

const StyledTextContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
  .left-side,
  .right-side {
    width: 48%;
    @media (max-width: 1000px) {
      width: 100%;
    }
    .about-block {
      h3 {
        margin: 0 0 15px 0;
        padding-top: 15px;
        color: var(--red);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2em;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
`

const StyledCTA = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`

export default function AboutUsPage({ data }) {
  const details = data.details,
    aboutData = data.about,
    artists = data.artists.nodes

  return (
    <div className="page">
      <Meta title="About Us" />
      <Layout>
        <Nav
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          name={details.shortName}
          logo={details.logo}
        />
        <MobileMenu
          phoneNumber={details.phone}
          phoneIcon={details.contactIcon}
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
        <main className="content">
          <PageTitle title={aboutData.pageTitle} />
          <Banner
            data={aboutData.bannerText}
            image={aboutData.bannerBackground}
          />

          <StyledTextContent>
            <div className="left-side">
              <RenderBlock
                block={aboutData.leftText}
                className={"about-block left-block"}
              />
            </div>
            <div className="right-side">
              <RenderBlock
                block={aboutData.rightText}
                className={"about-block left-block"}
              />
            </div>
          </StyledTextContent>
          <Artists artists={artists} />
          <StyledCTA>
            <button
              onClick={e => {
                navigate(`/gallery/${firstCategory}/1`)
              }}
            >
              Check out our work
            </button>
          </StyledCTA>
        </main>
        <Footer
          copyrightMessage={details.copyrightMessage}
          agencyName={details.agencyName}
          agencyLink={details.agencyLink}
        />
      </Layout>
    </div>
  )
}

export const siteDetails = graphql`
  query {
    details: sanityDetails {
      contactIcon {
        ...ImageWithPreview
      }
      logo {
        ...ImageWithPreview
      }
      phone
      longName
      copyrightMessage
      agencyName
      agencyLink
    }
    about: sanityAboutUs {
      bannerBackground {
        asset {
          url
          id
          publicUrl
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      bannerText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
      pageTitle
      leftText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        style
        list
      }
      rightText {
        children {
          _key
          _type
          marks
          text
        }
        _type
        _key
        list
        style
      }
    }
    artists: allSanityArtists {
      nodes {
        bio {
          children {
            _key
            _type
            marks
            text
          }
          _type
          _key
          list
          style
        }
        image {
          ...ImageWithPreview
        }
        name
        order
      }
    }
  }
`
